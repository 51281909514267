<template>
  <div justify="end">
    <v-dialog v-model="isVisibleShow" max-width="1200px">
      <v-card elevation="0">
        <v-card-title class="pb-4">
          <span class="text-h3">{{ $t("category") }}</span>
        </v-card-title>
        <v-card-text class="pa-0 grey lighten-4">
          <v-container>
            <v-row>
              <v-col cols="12" sm="4" md="4" class="px-10">
                <v-img v-if="category.image" :src="category.image.url" />
                <v-img v-else src="@/assets/product.png" />
              </v-col>
              <v-col cols="12" sm="8" md="8">
                <v-card elevation="0">
                  <v-card-text>
                    <v-simple-table>
                      <template v-slot:default>
                        <tbody>
                          <tr>
                            <td>{{ $t("name") }}</td>
                            <td>{{ category.name }}</td>
                          </tr>
                          <tr>
                            <td>{{ $t("code") }}</td>
                            <td>{{ category.code }}</td>
                          </tr>
                          <tr>
                            <td>{{ $t("status") }}</td>
                            <td>{{ category.status }}</td>
                          </tr>
                          <tr>
                            <td>{{ $t("parent") }}</td>
                            <td>
                              {{ category.parent ? category.parent.name : "_" }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row v-if="category.children && category.children.length > 0">
              <v-col cols="12" sm="12" md="12">
                <v-card elevation="0">
                  <v-card-title class="text-left">
                    <span>{{ $t("children") }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              {{ $t("name") }}
                            </th>
                            <th class="text-left">
                              {{ $t("code") }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in category.children" :key="item.id">
                            <td>{{ item.name }}</td>
                            <td>{{ item.code }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row v-if="category.products && category.products.length > 0">
              <v-col cols="12" sm="12" md="12">
                <v-card elevation="0">
                  <v-card-title class="text-left">
                    <span>{{ $t("products.products") }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              {{ $t("name") }}
                            </th>
                            <th class="text-left">
                              {{ $t("sku") }}
                            </th>
                            <th class="text-left">
                              {{ $t("code") }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in category.products" :key="item.id">
                            <td>{{ item.name }}</td>
                            <td>{{ item.sku }}</td>
                            <td>{{ item.code }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="closeForm()">
            {{ $t("close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      category: "categories/category",
    }),

    isVisibleShow: {
      get() {
        return this.$store.state.categories.isVisibleShow;
      },
      set(value) {
        this.$store.commit("categories/IS_VISIBLE_SHOW", value);
      },
    },
  },

  data() {
    return {};
  },
  methods: {
    closeForm() {
      this.$store.dispatch("categories/closeForm");
    },
  },
};
</script>
